import merge from 'lodash.merge';
import { nutmeg as nutmegPot, johnlewisfinanceGBR as johnLewisFinanceGBRPot } from '@nm-pot/languages';
import {
  nutmeg as nutmegPortfolio,
  johnlewisfinanceGBR as johnLewisFinanceGBRPortfolio,
  ipbGBR as ipbPortfolio,
} from '@nm-portfolio-lib-web/languages';
import { nutmeg as nutmegPayment, johnlewisfinanceGBR as johnlewisfinanceGBRPayment } from '@nm-payments/languages';
import Translations, { getCommonTranslations } from '@nm-utils-lib-web/translations';
import { getOrganisation, ORGANISATIONS } from '@nm-utils-lib-web/organisations';
import { sideMenuTranslations } from '@nm-ui-shared-lib-web/side-menu/languages';
import { clipboardCopyTranslations } from '@nm-ui-shared-lib-web/clipboard-copy/languages';
import {
  nutmeg as nutmegInvestorExperience,
  johnlewisfinanceGBR as johnLewisFinanceGBRInvestorExperience,
} from '@nm-customer/investor-experience/languages';
//prettier-ignore
import {
  genericErrorTranslations as nmGenericErrorTranslations
} from '@nm-ui-shared-lib-web/generic-error/languages/nutmeg';
//prettier-ignore
import {
  genericErrorTranslations as jlfGenericErrorTranslations
} from '@nm-ui-shared-lib-web/generic-error/languages/johnlewisfinance-gbr';
import {
  nutmeg as nmLayoutSideMenuTranslations,
  johnLewisFinanceGBR as jlfLayoutSideMenuTranslations,
} from '@nm-ui-shared-lib-web/layout-with-side-menu/languages';

import {
  nutmeg as nutmegDashboard,
  johnLewisFinanceGBR as johnLewisFinanceGBRDashboard,
  chaseGBR as chaseGBRDashboard,
} from '../languages';

const nutmegTranslations = {
  ...nutmegPot,
  ...nutmegDashboard,
  ...nutmegPortfolio,
  ...nutmegPayment,
  ...clipboardCopyTranslations,
  ...sideMenuTranslations,
  ...nmGenericErrorTranslations,
  ...nmLayoutSideMenuTranslations,
  ...nutmegInvestorExperience,
};
const chaseTranslations = {
  ...nutmegPot,
  ...merge({}, nutmegDashboard, chaseGBRDashboard),
  ...nutmegPortfolio,
  ...nutmegPayment,
  ...clipboardCopyTranslations,
  ...sideMenuTranslations,
  ...nmGenericErrorTranslations,
  ...nmLayoutSideMenuTranslations,
  ...nutmegInvestorExperience,
};

export default new Translations({
  language: 'en',
  defaultNS: getOrganisation(),
  resources: {
    en: {
      default: {
        ...nutmegTranslations,
      },
      [ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR]: {
        ...merge({}, nutmegDashboard, johnLewisFinanceGBRDashboard),
        ...johnLewisFinanceGBRPot,
        ...johnLewisFinanceGBRPortfolio,
        ...clipboardCopyTranslations,
        ...sideMenuTranslations,
        ...getCommonTranslations(ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR),
        ...jlfGenericErrorTranslations,
        ...jlfLayoutSideMenuTranslations,
        ...johnlewisfinanceGBRPayment,
        ...johnLewisFinanceGBRInvestorExperience,
      },
      [ORGANISATIONS.NUTMEG_GBR_BIGBEAR]: {
        ...nutmegTranslations,
        ...getCommonTranslations(ORGANISATIONS.NUTMEG_GBR_BIGBEAR),
      },
      [ORGANISATIONS.CHASE_GBR]: {
        ...chaseTranslations,
        ...getCommonTranslations(ORGANISATIONS.CHASE_GBR),
      },
      [ORGANISATIONS.IPB_GBR]: {
        ...nutmegTranslations,
        ...ipbPortfolio,
        ...getCommonTranslations(ORGANISATIONS.IPB_GBR),
      },
    },
  },
});
