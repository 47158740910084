import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Switch, Route } from 'react-router-dom';
import { useOnboardingJourneyService, STEPS } from '@nm-utils-lib-web/onboarding-journey-service';
import { useGetPots } from '@nm-pot/common/hooks';
import { WRAPPER_TYPES } from '@nm-pot/common/constants';
import ErrorBoundary from '@nm-utils-lib-web/error-boundary';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import { getErrorMessage } from '@nm-portfolio-lib-web/common/helpers';
import { Flags, useFlag, useVariant, Variants } from '@nm-utils-lib-web/flags';
import Loading from '@nutkit/component-loading';

import * as VIEWS from '../../constants/views';
import { getTheRoute } from '../../utils/getTheRoute';
import useSISAStore from '../../hooks/useStore';
import OpenOrTransferSISA from '../OpenOrTransferSISA';
import SetAllowanceSISA from '../SetAllowanceSISA';
import DeclarationSISA from '../DeclarationSISA';
import TransferSISAType from '../TransferSISA';
import TransferSISAOpen from '../TransferSISAOpen';
import TransferFullSISA from '../TransferFullSISA';
import TransferDownloadFormSISA from '../TransferDownloadFormSISA';
import TransferSubmittedSISA from '../TransferSubmittedSISA';
import { useJourneyMachine } from '../../journey/hooks';

const StartASISAContainer = (props) => {
  const { baseUrl, location, match, history, userNino, userUuid, t } = props;
  const { potUuid, onboarding } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { state, actions } = useSISAStore({ location });
  const { getNextStep, nextStep } = useOnboardingJourneyService();
  const { data: potsSISA, isLoading } = useGetPots({ customerUuid: userUuid, wrapper: WRAPPER_TYPES.GIA_ISA });
  const pot = potsSISA.find((pot) => pot.uuid === potUuid);
  const isaSettingsVariant = useVariant(Flags.ISA_SETTINGS_TEST);
  const isaSettingsOnboardingAndVariantB =
    onboarding && isaSettingsVariant.enabled && isaSettingsVariant.name === Variants.VARIANT_B;
  const isIncomePortfoliosFeatureEnabled = useFlag(Flags.INCOME_PORTFOLIOS);

  React.useEffect(() => {
    getNextStep(STEPS.ISA_SETTINGS);
  }, [getNextStep]);
  /*
   * Starting the xstate service by useMachine with our custom journey machine
   * It gives us the current state of the journey, and send method to broadcast events to the machine
   * We then also feed the application state to the journeyNavigation components
   */
  const context = {
    potUuid,
    baseUrl,
    location,
    match,
    history,
    isaSettingsOnboardingAndVariantB,
    isIncomePortfoliosFeatureEnabled,
  };
  const [current, send] = useJourneyMachine(context);
  const journeyData = {
    current,
    send,
    state,
    nextStep,
    onboarding,
    pot,
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ErrorBoundary
      fallback={
        <Notification dismissable={false} level={notificationLevels.ERROR}>
          {t(getErrorMessage())}
        </Notification>
      }
      contextualDescription="Boundary error in Start A SISA"
    >
      <div className="start-a-sisa-container" data-qa="start-a-sisa-container">
        <Switch>
          <Route
            exact
            path={getTheRoute(baseUrl, VIEWS.OPEN_OR_TRANSFER)}
            data-qa="open-or-transfer-route"
            render={(routeProps) => (
              <OpenOrTransferSISA
                journey={journeyData}
                intent={state.intent}
                setIntent={actions.setIntent}
                potName={pot?.name}
                {...routeProps}
              />
            )}
          />
          <Route
            exact
            path={getTheRoute(baseUrl, VIEWS.SET_ALLOWANCE)}
            data-qa="set-allowance-route"
            render={(routeProps) => (
              <SetAllowanceSISA
                journey={journeyData}
                autoFill={state.settings.autoFill}
                autoRenew={state.settings.autoRenew}
                amount={state.allowance}
                setAmount={actions.setAllowance}
                userUuid={userUuid}
                isaSettingsOnboardingAndVariantB={isaSettingsOnboardingAndVariantB}
                {...routeProps}
              />
            )}
          />
          <Route
            exact
            path={getTheRoute(baseUrl, VIEWS.DECLARATION)}
            data-qa="declaration-route"
            render={(routeProps) => (
              <DeclarationSISA
                userUuid={userUuid}
                journey={journeyData}
                backBtn={!state.nextStep}
                intent={state.intent}
                potUuid={state.potUuid}
                {...routeProps}
              />
            )}
          />
          <Route
            exact
            path={getTheRoute(baseUrl, VIEWS.TRANSFER_TYPE)}
            data-qa="transfer-type"
            render={(routeProps) => (
              <TransferSISAType
                journey={journeyData}
                transfer={state.transferType}
                setTransfer={actions.setTransfer}
                {...routeProps}
              />
            )}
          />
          <Route
            exact
            path={getTheRoute(baseUrl, VIEWS.TRANSFER_OPEN_ISA)}
            data-qa="transfer-open-isa"
            render={(routeProps) => (
              <TransferSISAOpen
                journey={journeyData}
                openISA={state.openISA}
                setOpenISA={actions.setOpenISA}
                userUuid={userUuid}
                {...routeProps}
              />
            )}
          />
          <Route
            exact
            path={getTheRoute(baseUrl, VIEWS.TRANSFER_FULL)}
            data-qa="transfer-full-sisa-route"
            render={(routeProps) => (
              <TransferFullSISA
                userNino={userNino}
                userUuid={userUuid}
                journey={journeyData}
                onboarding={onboarding}
                transferData={state.transferData}
                setTransferData={actions.setTransferData}
                {...routeProps}
              />
            )}
          />
          <Route
            exact
            path={getTheRoute(baseUrl, VIEWS.TRANSFER_PARTIAL)}
            data-qa="transfer-download-sisa-route"
            render={(routeProps) => (
              <TransferDownloadFormSISA
                journey={journeyData}
                transferData={state.transferData}
                openISA={state.openISA}
                transferType={state.transferType}
                userUuid={userUuid}
                onboarding={onboarding}
                {...routeProps}
              />
            )}
          />
          <Route
            exact
            path={getTheRoute(baseUrl, VIEWS.TRANSFER_AUTO_SUCCESS)}
            data-qa="transfer-submitted-sisa-route"
            render={(routeProps) => <TransferSubmittedSISA journey={journeyData} {...routeProps} />}
          />
        </Switch>
      </div>
    </ErrorBoundary>
  );
};

StartASISAContainer.propTypes = {
  baseUrl: PropTypes.string,
  t: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string, search: PropTypes.string }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({ isExact: PropTypes.bool, path: PropTypes.string }).isRequired,
  userNino: PropTypes.string.isRequired,
  userUuid: PropTypes.string.isRequired,
};

StartASISAContainer.defaultProps = {
  baseUrl: '',
};

export default StartASISAContainer;
