import { OUTGOING_ISA_WITHDRAWAL, OUTGOING_GIA_WITHDRAWAL } from '../constants/WrapperTransactions';

const getPendingWithdrawalData = ({ pots }) => {
  const pendingWithdrawalPots = pots.filter(({ pending: { withdrawals } }) => withdrawals > 0);
  const totalPendingWithdrawals = pendingWithdrawalPots
    .map(pot => pot.pending.withdrawals)
    .reduce((prev, next) => prev + next, 0);
  const hasPendingWrapperWithdrawals = pots.some(pot => {
    const wrapperTransactions = pot && pot.pending && pot.pending.wrapperTransactions;

    return (
      wrapperTransactions &&
      (wrapperTransactions.includes(OUTGOING_ISA_WITHDRAWAL) || wrapperTransactions.includes(OUTGOING_GIA_WITHDRAWAL))
    );
  });
  const hasPendingWithdrawals = pendingWithdrawalPots.length > 0 || hasPendingWrapperWithdrawals;

  return {
    pendingWithdrawalPots,
    totalPendingWithdrawals,
    hasPendingWithdrawals,
    hasPendingWrapperWithdrawals
  };
};

export default getPendingWithdrawalData;
