import { WRAPPER_TYPES } from '@nm-pot/common/constants';

const WRAPPER_TYPES_PRESERVED_ORDER = {
  [WRAPPER_TYPES.SISA]: 1,
  [WRAPPER_TYPES.GIA]: 2,
  [WRAPPER_TYPES.LISA]: 3,
  [WRAPPER_TYPES.PENSION]: 4,
  [WRAPPER_TYPES.JISA]: 5
};

export const sortWrapperTypes = array => {
  return array.sort((a, b) => {
    const orderA = WRAPPER_TYPES_PRESERVED_ORDER[a] || Infinity;
    const orderB = WRAPPER_TYPES_PRESERVED_ORDER[b] || Infinity;

    return orderA - orderB;
  });
};

export const getEligibleProducts = (eligibleProductsData, eligibleProductsError) => {
  if (eligibleProductsError) {
    return null;
  }

  let eligibleWrapper = eligibleProductsData.wrappers
    ? eligibleProductsData.wrappers.map(item => item.toLowerCase())
    : [];

  eligibleWrapper = sortWrapperTypes(eligibleWrapper);

  return eligibleWrapper;
};

export const isEligible = (eligibleProducts, wrapperType) =>
  !!eligibleProducts && eligibleProducts.includes(wrapperType);

export const isEligibilityUnknown = eligibleProducts => eligibleProducts === null;
