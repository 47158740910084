import React from 'react';
import { arrayOf, func, object, bool, string } from 'prop-types';
import { PotsOverview } from '@nm-pot/overview';
import { Heading } from '@nutkit/component-text';
import { useTranslation } from '@nm-utils-lib-web/translations';

import PendingWithdrawal from '../PendingWithdrawal';
import PendingTransfers from '../PendingTransfers';
import SystemPotOverview from '../SystemPotOverview';
import getPendingWithdrawalData from '../../helpers/getPendingWithdrawalData';

const NAVIGATION_TABS_TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.navigationTabs';

const IncomeTab = ({ pots, systemPot, updatePots, onError, userUuid, withGroupHeading, isAllTab }) => {
  const { t } = useTranslation();
  const {
    pendingWithdrawalPots,
    totalPendingWithdrawals,
    hasPendingWithdrawals,
    hasPendingWrapperWithdrawals
  } = getPendingWithdrawalData({ pots });

  return (
    <>
      {withGroupHeading && (
        <Heading level="3" data-qa="product-list__income-title">
          {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.INCOME.label`)}
        </Heading>
      )}
      {hasPendingWithdrawals && !isAllTab && (
        <PendingWithdrawal
          pendingWithdrawalPots={pendingWithdrawalPots}
          totalPendingWithdrawals={totalPendingWithdrawals}
          updatePots={updatePots}
          userUuid={userUuid}
          hasPendingWrapperWithdrawals={hasPendingWrapperWithdrawals}
          data-qa="product-list__income-tab__pending-withdrawal"
        />
      )}
      {!isAllTab && <PendingTransfers userUuid={userUuid} onCancel={updatePots} pots={pots} />}
      {Boolean(systemPot) && <SystemPotOverview systemPot={systemPot} />}
      {Boolean(pots.length) && (
        <PotsOverview customerUuid={userUuid} pots={pots} onClosePot={updatePots} onError={onError} />
      )}
    </>
  );
};

IncomeTab.propTypes = {
  pots: arrayOf(object).isRequired,
  systemPot: object,
  updatePots: func.isRequired,
  onError: func,
  userUuid: string.isRequired,
  withGroupHeading: bool,
  isAllTab: bool
};

IncomeTab.defaultProps = {
  onError: undefined,
  withGroupHeading: false,
  systemPot: undefined,
  isAllTab: false
};

export default IncomeTab;
