import React, { useMemo } from 'react';
import { JisaDependantsOverview } from '@nm-pot/overview';
import { arrayOf, func, object, string, bool } from 'prop-types';
import { Heading, headingLevels } from '@nutkit/component-text';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { POT_STATUSES } from '@nm-pot/common/constants';
import Inline, { inlineSpaces } from '@nutkit/component-inline';
import { useGetCustomerDependantPersonalInformation } from '@nm-customer/common/hooks';
import Loading from '@nutkit/component-loading';

const NAVIGATION_TABS_TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.navigationTabs';

const JISATab = ({ pots, drafts, onError, userUuid, withHeading }) => {
  const { t } = useTranslation();
  const {
    jisaDependants,
    isLoading: isJisaDependantsLoading,
    mutate: mutateJisaDependants
  } = useGetCustomerDependantPersonalInformation(userUuid, { onError });
  const potsToDisplay = useMemo(
    () => pots.filter(({ status }) => [POT_STATUSES.ACTIVE, POT_STATUSES.CLOSING].includes(status)),
    [pots]
  );
  const hasJisaDependants = jisaDependants && jisaDependants.length > 0;
  const hasNoPotsToShow = !drafts.length && !potsToDisplay.length;

  const handleRemoveJisaAccount = childCustomerId => {
    const newJisaDependants = jisaDependants.filter(({ customerId }) => customerId !== childCustomerId);

    mutateJisaDependants({ jisaDependants: newJisaDependants });
  };

  if (isJisaDependantsLoading) {
    return <Loading />;
  }

  if (!hasJisaDependants || hasNoPotsToShow) {
    return null;
  }

  return (
    <>
      {withHeading && (
        <Heading level={headingLevels.THREE} data-qa="product-list__jisa-title">
          <Inline space={inlineSpaces.SM}>{t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.jisa.label`)}</Inline>
        </Heading>
      )}
      <JisaDependantsOverview
        customerId={userUuid}
        pots={potsToDisplay}
        drafts={drafts}
        jisaDependants={jisaDependants}
        onRemoveJisaAccount={handleRemoveJisaAccount}
        onError={onError}
      />
    </>
  );
};

JISATab.propTypes = {
  pots: arrayOf(object).isRequired,
  drafts: arrayOf(object),
  onError: func,
  userUuid: string.isRequired,
  withHeading: bool
};

JISATab.defaultProps = {
  drafts: [],
  withHeading: false,
  onError: undefined
};

export default JISATab;
