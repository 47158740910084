import React from 'react';
import { string, func } from 'prop-types';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import Loading from '@nutkit/component-loading';

import PortfolioSummaryOverview from '../PortfolioSummaryOverview';
import { useGetCustomerPerformanceSummary } from '../../../../hooks/useGetCustomerPerformanceSummary';
import { useGetPerformanceSummary } from '../../../../hooks/useGetPerformanceSummary';
import { PERFORMANCE_INDICATORS, TIMEFRAME_OFFSETS } from '../../constants';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioSummary';

const PortfolioSummary = ({ customerFirstName, customerUuid, context, t, ...restProps }) => {
  const {
    data: performanceSummary,
    error: performanceSummaryError,
    isLoading: isPerformanceSummaryLoading
  } = useGetPerformanceSummary({
    customerUuid,
    context,
    shouldRetryOnError: false
  });
  const { portfolioValue, netContributions, returnValue, lastUpdatedAt } = performanceSummary || {};
  // This hook is getting the data from our
  // node performance service, based on a flag
  const {
    data: customerPerformanceSummary,
    error: customerPerformanceSummaryError,
    isLoading: isCustomerPerformanceSummaryLoading
  } = useGetCustomerPerformanceSummary({
    customerUuid,
    timeframe: [TIMEFRAME_OFFSETS.ALL],
    indicatorType: [PERFORMANCE_INDICATORS.TWRR, PERFORMANCE_INDICATORS.SR],
    context
  });

  if (performanceSummaryError) {
    return (
      <Notification level={notificationLevels.ERROR} dismissable={false} data-qa="portfolio-summary__error" alternative>
        {t(`${TRANSLATION_NAMESPACE}.error.message`)}
      </Notification>
    );
  }

  let simpleReturnPercent, timeWeightedReturnPercent, twrrLastUpdatedAt;
  let twrrExcluded = false;

  if (customerPerformanceSummary) {
    simpleReturnPercent = customerPerformanceSummary[TIMEFRAME_OFFSETS.ALL].indicators.SR.value;
    timeWeightedReturnPercent = customerPerformanceSummary[TIMEFRAME_OFFSETS.ALL].indicators.TWRR.value;
    twrrLastUpdatedAt = customerPerformanceSummary[TIMEFRAME_OFFSETS.ALL].indicators.TWRR.updatedAt;
    twrrExcluded = customerPerformanceSummary[TIMEFRAME_OFFSETS.ALL].indicators.TWRR.isExcluded || false;
  }

  return (
    <section data-qa="portfolio-summary__container">
      {isPerformanceSummaryLoading && !performanceSummary ? (
        <Loading data-qa="portfolio-summary__loading" />
      ) : (
        <PortfolioSummaryOverview
          customerFirstName={customerFirstName}
          portfolioValue={portfolioValue}
          netContributions={netContributions}
          returnValue={returnValue}
          simpleReturnPercent={simpleReturnPercent}
          timeWeightedReturnPercent={timeWeightedReturnPercent}
          lastUpdatedAt={lastUpdatedAt}
          twrrLastUpdatedAt={twrrLastUpdatedAt}
          twrrExcluded={twrrExcluded}
          isCustomerPerformanceSummaryLoading={isCustomerPerformanceSummaryLoading}
          customerPerformanceSummaryError={customerPerformanceSummaryError}
          data-qa="portfolio-summary-overview"
          {...restProps}
        />
      )}
    </section>
  );
};

PortfolioSummary.defaultProps = {
  context: 'Dashboard'
};

PortfolioSummary.propTypes = {
  customerFirstName: string.isRequired,
  customerUuid: string.isRequired,
  context: string,
  t: func.isRequired
};

export default PortfolioSummary;
