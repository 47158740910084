import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { NOT_FOUND } from 'http-status-codes';
import ErrorBoundary from '@nm-utils-lib-web/error-boundary';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import Section, { stackSpacing } from '@nutkit/component-section';
import {
  WRAPPER_TYPES,
  INVESTING_FOR_INCOME,
  CASH,
  INCOME,
  GROWTH,
  INVESTING_FOR_GROWTH,
  POT_STATUSES
} from '@nm-pot/common/constants';
import Navigation from '@nutkit/component-navigation';
import { useGetCurrentProducts } from '@nm-pot/common/hooks';
import useGetEligibleProducts from '@nm-portfolio-lib-web/common/hooks/useGetEligibleProducts';
import prismicImageUUIDs from '@nm-portfolio-lib-web/product-select/constants/imagesUuid';
import useGetISAStatus from '@nm-portfolio-lib-web/common/hooks/useGetISAStatus';
import { ISA_STATUS_ERRORS } from '@nm-portfolio-lib-web/common/services/serviceConstants';
import Loading from '@nutkit/component-loading';
import { useBreakpoint, breakpointDirections, breakpoints } from '@nutkit/react-utils';
import { Portfolio } from '@nm-utils-lib-web/routes';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';
import { IncomingTransfers } from '@nm-payments/transfers/components/IncomingTransfers';

import { parseSearchParameters } from '../../helpers/parseSearchParameters';
import useGetSystemPots from '../../hooks/useGetSystemPots/useGetSystemPots';
import { INCOME_CASH } from '../../constants/systemPotTypes';

import {
  isTabAvailable,
  getEligibleProducts,
  getTabsSortComparator,
  getSISATabLabelKey,
  isEligible,
  isEligibilityUnknown,
  getTotalByWrapperType,
  getAllTabsTotalValue
} from './helpers';
import { useAvailableDrafts } from './hooks';
import SISATab from './components/SISATab';
import JISATab from './components/JISATab';
import LISATab from './components/LISATab';
import PensionTab from './components/PensionTab';
import IncomeTab from './components/IncomeTab';
import CashPotsSection from './components/CashPotsSection';
import DraftPotsSection from './components/DraftPotsSection';
import ProductSelect from './components/ProductSelect';
import { ProductNavigationItem } from './components/ProductNavigationItem';
import InvestTowardsNewGoal from './components/InvestTowardsNewGoal';
import { PRODUCT_TABS } from './constants/ProductTabs';
import Notifications from './components/Notifications';
import GrowthTab from './components/GrowthTab';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.navigationTabs';
const ACTIVE_PRODUCT_QUERY_PARAM = 'active-product-tab';
const createProductTab = (id, component) => ({
  id,
  image: prismicImageUUIDs[window.NutmegConfig.PRISMIC_TARGET][id],
  component,
  url: Portfolio.getDashboardWithParamsPath({ activeProductTab: id })
});
const baseProductTabs = [
  createProductTab(WRAPPER_TYPES.SISA, SISATab),
  createProductTab(WRAPPER_TYPES.LISA, LISATab),
  createProductTab(WRAPPER_TYPES.JISA, JISATab),
  createProductTab(WRAPPER_TYPES.PENSION, PensionTab)
];
const createStrategyTab = (id, component) => ({
  id,
  component,
  url: Portfolio.getDashboardWithParamsPath({ activeProductTab: id })
});
const investmentStrategyTabs = [
  createStrategyTab(PRODUCT_TABS.INCOME, IncomeTab),
  createStrategyTab(PRODUCT_TABS.GROWTH, GrowthTab),
  createStrategyTab(PRODUCT_TABS.CASH, CashPotsSection)
];

const ProductList = ({ t, userUuid, location, shouldShowLisaTransfersInTab }) => {
  const isLISATransferEnabled = useFlag(Flags.LISA_TRANSFERS);
  const isIncomePortfolioEnabled = useFlag(Flags.FE_INCOME_PORTFOLIOS_EI);
  const { matchesCondition: isLessThanMediumBreakpoint } = useBreakpoint(breakpoints.MD, breakpointDirections.DOWN);
  const { isLoading: productsLoading, error: productsError, getPots, ...allProducts } = useGetCurrentProducts({
    customerUuid: userUuid,
    useAllStatusesInRequest: true
  });
  const {
    isLoading: incomeCashSystemPotLoading,
    error: incomeCashSystemPotError,
    data: incomeCashSystemPot
  } = useGetSystemPots({
    customerUuid: userUuid,
    type: INCOME_CASH,
    shouldMakeRequest: isIncomePortfolioEnabled
  });
  const systemPot = incomeCashSystemPot?.systemPots?.find(Boolean);
  const products = {
    ...allProducts,
    [WRAPPER_TYPES.SISA]: {
      ...allProducts[WRAPPER_TYPES.SISA],
      data: allProducts[WRAPPER_TYPES.SISA]?.data.filter(({ status }) => status !== POT_STATUSES.SAVED)
    }
  };

  if (!isIncomePortfolioEnabled) {
    products[WRAPPER_TYPES.SISA].data = products[WRAPPER_TYPES.SISA]?.data?.filter(
      ({ investmentStrategy }) => investmentStrategy !== INVESTING_FOR_INCOME
    );
  }

  if (isIncomePortfolioEnabled) {
    const incomeProducts = products[WRAPPER_TYPES.SISA]?.data.filter(
      ({ investmentStrategy }) => investmentStrategy === INVESTING_FOR_INCOME
    );
    const cashProducts = products[WRAPPER_TYPES.SISA]?.data.filter(
      ({ investmentStrategy }) => investmentStrategy === CASH
    );
    const sisaProducts = products[WRAPPER_TYPES.SISA]?.data.filter(
      ({ investmentStrategy }) => investmentStrategy === INVESTING_FOR_GROWTH
    );
    const lisaProducts = products[WRAPPER_TYPES.LISA]?.data.filter(({ status }) => status !== POT_STATUSES.SAVED) ?? [];
    const pensionProducts =
      products[WRAPPER_TYPES.PENSION]?.data.filter(({ status }) => status !== POT_STATUSES.SAVED) ?? [];
    const jisaProducts = products[WRAPPER_TYPES.JISA]?.data.filter(({ status }) => status !== POT_STATUSES.SAVED) ?? [];
    const growthProducts = [...sisaProducts, ...lisaProducts, ...pensionProducts, ...jisaProducts];

    products[WRAPPER_TYPES.SISA] = { isPresent: Boolean(sisaProducts.length), data: sisaProducts };
    products[INCOME] = {
      isPresent: Boolean(incomeProducts.length) || Boolean(systemPot?.currentValue),
      data: incomeProducts
    };
    products[CASH] = { isPresent: Boolean(cashProducts.length), data: cashProducts };
    products[GROWTH] = { isPresent: Boolean(growthProducts.length), data: growthProducts };
  }
  const { isLoading: draftsLoading, drafts, error: draftsError, getDrafts } = useAvailableDrafts({
    customerUuid: userUuid
  });
  const {
    data: eligibleProductsData,
    error: eligibleProductsError,
    isLoading: eligibleProductsLoading
  } = useGetEligibleProducts({ customerUuid: userUuid, isLISATransferEnabled });
  const { data: isaStatusData, error: isaStatusError, isaStatus, isLoading: isaStatusLoading } = useGetISAStatus({
    customerUuid: userUuid
  });

  if (
    eligibleProductsLoading ||
    (productsLoading && !productsError) ||
    (isaStatusLoading && !isaStatusError) ||
    (incomeCashSystemPotLoading && !incomeCashSystemPotError) ||
    (draftsLoading && !drafts && !draftsError)
  ) {
    return <Loading data-qa="product-list__loading" />;
  }

  if (productsError || (isaStatusError && isaStatusError !== ISA_STATUS_ERRORS[NOT_FOUND])) {
    return (
      <Notification level={notificationLevels.ERROR} dismissable={false} data-qa="product-list__error-notification">
        {t('dashboard.common.error.defaultText')}
      </Notification>
    );
  }

  const statuses = {
    [WRAPPER_TYPES.SISA]: isaStatus,
    [WRAPPER_TYPES.LISA]: isaStatusData && isaStatusData.byWrapper && isaStatusData.byWrapper[WRAPPER_TYPES.LISA]
  };
  let draftProducts = drafts;

  if (isIncomePortfolioEnabled) {
    const pensionDraftProducts =
      allProducts[WRAPPER_TYPES.PENSION]?.data.filter(({ status }) => status === POT_STATUSES.SAVED) ?? [];

    draftProducts = {
      ...draftProducts,
      [WRAPPER_TYPES.LISA]: {
        ...draftProducts[WRAPPER_TYPES.LISA]
      },
      [WRAPPER_TYPES.PENSION]: {
        data: pensionDraftProducts,
        isPresent: pensionDraftProducts.length > 0
      },
      [WRAPPER_TYPES.JISA]: {
        ...draftProducts[WRAPPER_TYPES.JISA]
      }
    };
  }

  const eligibleProducts = getEligibleProducts(eligibleProductsData, eligibleProductsError);
  const visibleDrafts = isEligibilityUnknown(eligibleProducts) ? undefined : draftProducts;
  const productTabs = isIncomePortfolioEnabled ? investmentStrategyTabs : baseProductTabs;
  const availableTabs = productTabs
    .filter(tab => isTabAvailable(tab.id, eligibleProducts, products, statuses))
    .sort(getTabsSortComparator(products, visibleDrafts))
    .map(tab => {
      return {
        ...tab,
        total: getTotalByWrapperType(products[tab.id].data),
        isPresent: products[tab.id].isPresent,
        label: t(
          `${TRANSLATION_NAMESPACE}.${
            tab.id === PRODUCT_TABS.SISA ? getSISATabLabelKey(eligibleProducts, products, isaStatus) : tab.id
          }.label`
        )
      };
    });
  const allTab = {
    ...createProductTab(PRODUCT_TABS.ALL, null),
    label: t(`${TRANSLATION_NAMESPACE}.all.label`),
    total: getAllTabsTotalValue(availableTabs),
    isPresent: true
  };

  if (!availableTabs.length) {
    return eligibleProductsError ? (
      <Notification
        level={notificationLevels.ERROR}
        dismissable={false}
        data-qa="product-list__error-notification-eligibility"
      >
        {t('dashboard.common.error.defaultText')}
      </Notification>
    ) : null;
  }

  const areMultipleTabsVisible = availableTabs.length > 1;

  if (areMultipleTabsVisible) {
    availableTabs.unshift(allTab);
  }

  const searchTabId = location.search && parseSearchParameters(location.search)[ACTIVE_PRODUCT_QUERY_PARAM];
  const { id: activeTabId, component: ActiveTabComponent } =
    availableTabs.find(item => item.id === searchTabId) || availableTabs[0];

  return (
    <ErrorBoundary
      fallback={
        <Notification level={notificationLevels.ERROR} dismissable={false}>
          {t('dashboard.common.error.defaultText')}
        </Notification>
      }
      contextualDescription="[Dashboard] Product List – Unable to render products"
    >
      {areMultipleTabsVisible &&
        (isLessThanMediumBreakpoint ? (
          <ProductSelect
            data-qa="product-list__navigation-select"
            products={availableTabs}
            selectedProductId={activeTabId}
            shouldShowIcons={!isIncomePortfolioEnabled}
          />
        ) : (
          <Navigation data-qa="product-list__navigation">
            {availableTabs.map(tab => (
              <ProductNavigationItem
                isActive={tab.id === activeTabId}
                key={tab.label}
                data-qa={`navigation-item__${tab.id}`}
                {...tab}
              />
            ))}
          </Navigation>
        ))}
      <Section data-qa={`product-list__${activeTabId}`}>
        {(!areMultipleTabsVisible || activeTabId === PRODUCT_TABS.ALL) && (
          <IncomingTransfers wrapperType={null} customerUuid={userUuid} />
        )}
        {(!areMultipleTabsVisible || (activeTabId === PRODUCT_TABS.ALL && isIncomePortfolioEnabled)) && (
          <Notifications userUuid={userUuid} products={products} updatePots={getPots} />
        )}
        {activeTabId === PRODUCT_TABS.ALL ? (
          <>
            {availableTabs
              .filter(tab => tab.id !== PRODUCT_TABS.ALL)
              .map(({ component: ProductComponent, id }) => (
                <Section stackSpacing={stackSpacing.LG} key={id}>
                  <ProductComponent
                    pots={products[id].data}
                    isPresent={products[id].isPresent}
                    drafts={!isIncomePortfolioEnabled ? visibleDrafts?.[id]?.data : []}
                    isDraftPresent={!isIncomePortfolioEnabled && visibleDrafts?.[id]?.isPresent}
                    updatePots={getPots}
                    updateDrafts={getDrafts}
                    userUuid={userUuid}
                    eligibilityUnknown={isEligibilityUnknown(eligibleProducts)}
                    isEligible={isEligible(eligibleProducts, id)}
                    {...(id === PRODUCT_TABS.SISA
                      ? {
                          eligibleSISA: isEligible(eligibleProducts, WRAPPER_TYPES.SISA),
                          eligibleGIA: isEligible(eligibleProducts, WRAPPER_TYPES.GIA),
                          isaStatus,
                          tabLabelKey: getSISATabLabelKey(eligibleProducts, products, isaStatus)
                        }
                      : {})}
                    {...(id === PRODUCT_TABS.INCOME && { systemPot })}
                    withHeading={!isIncomePortfolioEnabled}
                    withGroupHeading={
                      isIncomePortfolioEnabled && [PRODUCT_TABS.INCOME, PRODUCT_TABS.GROWTH].includes(id)
                    }
                    shouldShowLisaTransfersInTab={shouldShowLisaTransfersInTab}
                    onError={() => {}}
                    isAllTab
                  />
                </Section>
              ))}
            {isIncomePortfolioEnabled && (
              <Section stackSpacing={stackSpacing.LG}>
                <DraftPotsSection userUuid={userUuid} drafts={visibleDrafts} updateDrafts={getDrafts} />
              </Section>
            )}
            <InvestTowardsNewGoal
              userUuid={userUuid}
              shouldShowSisaSectionOnly={false}
              isPensionEligible={isEligible(eligibleProducts, WRAPPER_TYPES.PENSION)}
              isPensionPresent={products[WRAPPER_TYPES.PENSION]?.isPresent}
              eligibleSISA={isEligible(eligibleProducts, WRAPPER_TYPES.SISA)}
              eligibleGIA={isEligible(eligibleProducts, WRAPPER_TYPES.GIA)}
              isLisaPresent={products[WRAPPER_TYPES.LISA]?.isPresent}
              isLisaDraftPresent={visibleDrafts?.[WRAPPER_TYPES.LISA]?.isPresent}
              isLisaEligible={isEligible(eligibleProducts, WRAPPER_TYPES.LISA)}
              isJisaEligible={isEligible(eligibleProducts, WRAPPER_TYPES.JISA)}
              isJisaPresent={products[WRAPPER_TYPES.JISA]?.isPresent}
              shouldShowLisaTransfersInTab={shouldShowLisaTransfersInTab}
            />
          </>
        ) : (
          <>
            <Section stackSpacing={stackSpacing.LG}>
              <ActiveTabComponent
                pots={products[activeTabId]?.data}
                isPresent={products[activeTabId].isPresent}
                drafts={!isIncomePortfolioEnabled ? visibleDrafts?.[activeTabId]?.data : []}
                isDraftPresent={!isIncomePortfolioEnabled && visibleDrafts?.[activeTabId]?.isPresent}
                updatePots={getPots}
                updateDrafts={getDrafts}
                userUuid={userUuid}
                eligibilityUnknown={isEligibilityUnknown(eligibleProducts)}
                isEligible={isEligible(eligibleProducts, activeTabId)}
                {...(activeTabId === PRODUCT_TABS.SISA
                  ? {
                      eligibleSISA: isEligible(eligibleProducts, WRAPPER_TYPES.SISA),
                      eligibleGIA: isEligible(eligibleProducts, WRAPPER_TYPES.GIA),
                      isaStatus,
                      tabLabelKey: getSISATabLabelKey(eligibleProducts, products, isaStatus)
                    }
                  : {})}
                {...(activeTabId === PRODUCT_TABS.INCOME && { systemPot })}
                withGroupHeading={
                  isIncomePortfolioEnabled && [PRODUCT_TABS.INCOME, PRODUCT_TABS.GROWTH].includes(activeTabId)
                }
                shouldShowLisaTransfersInTab={shouldShowLisaTransfersInTab}
                onError={() => {}}
              />
            </Section>

            {isIncomePortfolioEnabled && !areMultipleTabsVisible && (
              <Section stackSpacing={stackSpacing.LG}>
                <DraftPotsSection userUuid={userUuid} drafts={visibleDrafts} updateDrafts={getDrafts} />
              </Section>
            )}

            {isIncomePortfolioEnabled && (
              <InvestTowardsNewGoal
                userUuid={userUuid}
                shouldShowSisaSectionOnly={[PRODUCT_TABS.INCOME, PRODUCT_TABS.CASH].includes(activeTabId)}
                isPensionEligible={isEligible(eligibleProducts, WRAPPER_TYPES.PENSION)}
                isPensionPresent={products[WRAPPER_TYPES.PENSION]?.isPresent}
                eligibleSISA={isEligible(eligibleProducts, WRAPPER_TYPES.SISA)}
                eligibleGIA={isEligible(eligibleProducts, WRAPPER_TYPES.GIA)}
                isLisaPresent={products[WRAPPER_TYPES.LISA]?.isPresent}
                isLisaDraftPresent={visibleDrafts?.[WRAPPER_TYPES.LISA]?.isPresent}
                isLisaEligible={isEligible(eligibleProducts, WRAPPER_TYPES.LISA)}
                isJisaEligible={isEligible(eligibleProducts, WRAPPER_TYPES.JISA)}
                isJisaPresent={products[WRAPPER_TYPES.JISA]?.isPresent}
                shouldShowLisaTransfersInTab={shouldShowLisaTransfersInTab}
              />
            )}
          </>
        )}
      </Section>
    </ErrorBoundary>
  );
};

ProductList.propTypes = {
  t: func.isRequired,
  userUuid: string.isRequired,
  location: shape({
    search: string
  }).isRequired,
  shouldShowLisaTransfersInTab: bool.isRequired
};

export default ProductList;
