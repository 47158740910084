import React from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { event as trackEvent } from '@nm-utils-lib-web/analytics';
import Select from '@nutkit/component-select';
import Section, { stackSpacing } from '@nutkit/component-section';
import fetchImage, { imageSizes } from '@nm-marketing/prismic-utils';
import { Flags, useFlag } from '@nm-utils-lib-web/flags';

import { ProductSelectOptionTitle, ProductSelectOptionSelectedTitle } from '../ProductSelectOptionTitle';
import { productListStrategyTabClickedPayload } from '../../../../tracking/events/productList';

const getOptionWithImage = ({ id, image, ...rest }, shouldShowIcons) => {
  const imageSource = shouldShowIcons
    ? fetchImage(window.NutmegConfig.PRISMIC_TARGET, image, imageSizes.MD, 'png', {
        fit: 'fill',
        fill: 'solid',
      })
    : null;

  return {
    id,
    title: <ProductSelectOptionTitle imageSource={imageSource} {...rest} />,
    selectedTitle: <ProductSelectOptionSelectedTitle {...rest} />,
  };
};

const ProductSelect = ({ products, selectedProductId, shouldShowIcons, history: { push }, 'data-qa': dataQa }) => {
  const isIncomePortfolioEnabled = useFlag(Flags.FE_INCOME_PORTFOLIOS_EI);

  const onProductSelect = (id) => {
    const { url } = products.find(({ id: productId }) => productId === id);

    if (isIncomePortfolioEnabled && url) {
      trackEvent(productListStrategyTabClickedPayload(id));
    }

    push(url);
  };

  return (
    <Section stackSpacing={stackSpacing.SM}>
      <Select
        noStack
        onSelect={onProductSelect}
        options={products.map((product) => getOptionWithImage(product, shouldShowIcons))}
        restrictHeight
        value={selectedProductId}
        data-qa={dataQa}
      />
    </Section>
  );
};

ProductSelect.propTypes = {
  products: arrayOf(
    shape({
      url: string.isRequired,
      label: string.isRequired,
      image: string.isRequired,
      id: string.isRequired,
      total: number.isRequired,
      isPresent: bool.isRequired,
    }),
  ).isRequired,
  selectedProductId: string.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  'data-qa': string,
  shouldShowIcons: bool.isRequired,
};

ProductSelect.defaultProps = {
  'data-qa': 'product-select',
};

export default ProductSelect;
