import React from 'react';
import { arrayOf, func, object, bool, string, shape } from 'prop-types';
import { PotsOverview } from '@nm-pot/overview';
import useGetUnallocatedCash from '@nm-payments/common/hooks/useGetUnallocatedCash';
import Panel from '@nutkit/component-panel';
import { Heading, headingLevels } from '@nutkit/component-text';
import { linkSizes, buttonCtas } from '@nutkit/component-link';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { Portfolio } from '@nm-utils-lib-web/routes';
import { FIND_OUT_MORE_ISA } from '@nm-utils-lib-web/routes/supportArticles';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import { WRAPPER_TYPES } from '@nm-pot/common/constants';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';

import { getSISAEmptyStateHeadingKey } from '../../helpers';
import UnallocatedCash from '../UnallocatedCash';
import PendingWithdrawal from '../PendingWithdrawal';
import PendingTransfers from '../PendingTransfers';
import { productListEmptyProductClickedPayload } from '../../../../tracking/events/productList';
import getPendingWithdrawalData from '../../helpers/getPendingWithdrawalData';

import EmptyStateDescription from './EmptyStateDescription';

const { DASHBOARD_HOST } = global.NutmegConfig;
const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.sisaTab';
const NAVIGATION_TABS_TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.navigationTabs';

const SISATab = ({
  t,
  pots,
  drafts,
  updatePots,
  updateDrafts,
  onError,
  isPresent,
  isDraftPresent,
  userUuid,
  eligibleGIA,
  eligibleSISA,
  isaStatus,
  withHeading,
  tabLabelKey,
  isAllTab
}) => {
  const isIncomePortfolioEnabled = useFlag(Flags.FE_INCOME_PORTFOLIOS_EI);
  const { hasUnallocatedCash, data } = useGetUnallocatedCash({ userUuid });
  const {
    pendingWithdrawalPots,
    totalPendingWithdrawals,
    hasPendingWithdrawals,
    hasPendingWrapperWithdrawals
  } = getPendingWithdrawalData({ pots });

  if (!isPresent && !isaStatus.active) {
    return (
      <>
        {withHeading && (
          <Heading level={headingLevels.THREE} data-qa="product-list__sisa-title">
            {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.${tabLabelKey}.label`)}
          </Heading>
        )}
        {!isIncomePortfolioEnabled && hasUnallocatedCash && (
          <UnallocatedCash amount={data.currentValue} potUuid={data.uuid} />
        )}
        {isDraftPresent && (
          <PotsOverview customerUuid={userUuid} drafts={drafts} updateDrafts={updateDrafts} onError={onError} />
        )}
        <Panel data-qa="product-list__empty-state__panel">
          <Heading level={headingLevels.TWO}>
            {t(getSISAEmptyStateHeadingKey(TRANSLATION_NAMESPACE, eligibleGIA, eligibleSISA))}
          </Heading>
          <EmptyStateDescription
            eligibleGIA={eligibleGIA}
            eligibleSISA={eligibleSISA}
            userUuid={userUuid}
            onError={onError}
            learMore={
              <LinkWithTracking
                href={FIND_OUT_MORE_ISA}
                eventPayload={productListEmptyProductClickedPayload({ wrapperType: WRAPPER_TYPES.SISA })}
                aria-label={t(`${TRANSLATION_NAMESPACE}.emptyState.learnMoreLink.ariaLabel`)}
                size={linkSizes.MD}
                data-qa="product-list__sisa-tab__create-new"
                isExternal
              >
                {t(`${TRANSLATION_NAMESPACE}.emptyState.learnMoreLink.label`)}
              </LinkWithTracking>
            }
          />
          <ButtonGroup align={buttonGroupAlignments.RIGHT} stackOnMobile>
            {eligibleGIA && (
              <LinkWithTracking
                href={`${DASHBOARD_HOST}${Portfolio.PRODUCT_GIA_INFORMATION_PATH}`}
                button
                buttonCta={buttonCtas.PRIMARY}
                aria-label={t(`${TRANSLATION_NAMESPACE}.emptyState.openGIALink.ariaLabel`)}
                size={linkSizes.SM}
                data-qa="product-list__sisa-empty-state__open-gia"
                eventPayload={productListEmptyProductClickedPayload({
                  option: 'open_gia',
                  wrapperType: WRAPPER_TYPES.SISA
                })}
              >
                {t(`${TRANSLATION_NAMESPACE}.emptyState.openGIALink.label`)}
              </LinkWithTracking>
            )}
            {eligibleSISA && (
              <LinkWithTracking
                href={Portfolio.PRODUCT_SISA_INFORMATION_PATH}
                button
                buttonCta={buttonCtas.PRIMARY}
                aria-label={t(`${TRANSLATION_NAMESPACE}.emptyState.openISALink.ariaLabel`)}
                size={linkSizes.SM}
                data-qa="product-list__sisa-empty-state__open-isa"
                eventPayload={productListEmptyProductClickedPayload({
                  option: 'open_or_transfer',
                  wrapperType: WRAPPER_TYPES.SISA
                })}
              >
                {t(`${TRANSLATION_NAMESPACE}.emptyState.openISALink.label`)}
              </LinkWithTracking>
            )}
          </ButtonGroup>
        </Panel>
      </>
    );
  }

  return (
    <div data-qa="product-list__sisa_content">
      {withHeading && (
        <Heading level={headingLevels.THREE} data-qa="product-list__sisa-title">
          {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.${tabLabelKey}.label`)}
        </Heading>
      )}
      {!isIncomePortfolioEnabled && hasUnallocatedCash && (
        <UnallocatedCash amount={data.currentValue} potUuid={data.uuid} />
      )}
      {isAllTab && isIncomePortfolioEnabled
        ? null
        : hasPendingWithdrawals && (
            <PendingWithdrawal
              pendingWithdrawalPots={pendingWithdrawalPots}
              totalPendingWithdrawals={totalPendingWithdrawals}
              updatePots={updatePots}
              userUuid={userUuid}
              hasPendingWrapperWithdrawals={hasPendingWrapperWithdrawals}
              data-qa="product-list__sisa-tab__pending-withdrawal"
            />
          )}
      {isAllTab && isIncomePortfolioEnabled ? null : (
        <PendingTransfers userUuid={userUuid} onCancel={updatePots} pots={pots} />
      )}
      {(isPresent || isDraftPresent) && (
        <PotsOverview
          customerUuid={userUuid}
          pots={pots}
          drafts={drafts}
          onClosePot={updatePots}
          updateDrafts={updateDrafts}
          onError={onError}
        />
      )}
    </div>
  );
};

SISATab.propTypes = {
  t: func.isRequired,
  isPresent: bool.isRequired,
  pots: arrayOf(object).isRequired,
  updatePots: func.isRequired,
  updateDrafts: func.isRequired,
  onError: func,
  userUuid: string.isRequired,
  eligibleGIA: bool.isRequired,
  eligibleSISA: bool.isRequired,
  isaStatus: shape({
    active: bool.isRequired
  }).isRequired,
  drafts: arrayOf(object),
  isDraftPresent: bool,
  withHeading: bool,
  tabLabelKey: string.isRequired,
  isAllTab: bool
};

SISATab.defaultProps = {
  drafts: [],
  isDraftPresent: false,
  withHeading: false,
  onError: undefined,
  isAllTab: false
};

export default SISATab;
