import { WRAPPER_TYPES, INCOME, CASH, GROWTH } from '@nm-pot/common/constants';

export const PRODUCT_TABS = {
  ALL: 'all',
  SISA: WRAPPER_TYPES.SISA,
  LISA: WRAPPER_TYPES.LISA,
  JISA: WRAPPER_TYPES.JISA,
  PENSION: WRAPPER_TYPES.PENSION,
  INCOME,
  GROWTH,
  CASH
};
