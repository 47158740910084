import { WRAPPER_TYPES, POT_STATUSES, CASH, INCOME, GROWTH } from '@nm-pot/common/constants';

import { PRODUCT_TABS } from '../constants/ProductTabs';

import { isEligible } from './productEligibility';

export const isSISAAvailable = (eligibleProducts, products, status) =>
  isEligible(eligibleProducts, WRAPPER_TYPES.SISA) ||
  isEligible(eligibleProducts, WRAPPER_TYPES.GIA) ||
  (products[WRAPPER_TYPES.SISA] && products[WRAPPER_TYPES.SISA].isPresent) ||
  status[WRAPPER_TYPES.SISA].active;

export const isLISAAvailable = (eligibleProducts, products, status) => {
  // returns true if user has LISA pots
  if (products[WRAPPER_TYPES.LISA] && products[WRAPPER_TYPES.LISA].isPresent) {
    return true;
  }

  const eligible = isEligible(eligibleProducts, WRAPPER_TYPES.LISA);

  // returns false if user does not have LISA pots AND is not eligible to open LISA
  if (!eligible) {
    return false;
  }

  const lisaStatus = status[WRAPPER_TYPES.LISA];

  // returns true if user is eligible to open LISA
  // AND there is no info about LISA status (e.g. ISA status endpoint returns 404)
  if (!lisaStatus) {
    return true;
  }

  // returns true if user is eligible to open LISA AND does not hold OR held LISA
  return !lisaStatus.holds && !lisaStatus.held;
};

export const isJISAAvailable = (eligibleProducts, products) =>
  isEligible(eligibleProducts, WRAPPER_TYPES.JISA) ||
  (products[WRAPPER_TYPES.JISA] &&
    products[WRAPPER_TYPES.JISA].data.some(({ status }) => status === POT_STATUSES.ACTIVE));

export const isPensionAvailable = (eligibleProducts, products) =>
  isEligible(eligibleProducts, WRAPPER_TYPES.PENSION) ||
  (products[WRAPPER_TYPES.PENSION] && products[WRAPPER_TYPES.PENSION].isPresent);

export const isIncomeAvailable = (eligibleProducts, products) => products[INCOME].isPresent;
export const isCashAvailable = (eligibleProducts, products) => products[CASH].isPresent;
export const isGrowthAvailable = (eligibleProducts, products) => products[GROWTH].isPresent;

export const isTabAvailable = (productTabToCheck, eligibleProducts, products, statuses) => {
  const tabAvailabilityConditions = {
    [PRODUCT_TABS.SISA]: isSISAAvailable,
    [PRODUCT_TABS.LISA]: isLISAAvailable,
    [PRODUCT_TABS.JISA]: isJISAAvailable,
    [PRODUCT_TABS.PENSION]: isPensionAvailable,
    [PRODUCT_TABS.INCOME]: isIncomeAvailable,
    [PRODUCT_TABS.GROWTH]: isGrowthAvailable,
    [PRODUCT_TABS.CASH]: isCashAvailable
  };

  if (eligibleProducts === null) {
    return products[productTabToCheck] && products[productTabToCheck].isPresent;
  }

  return tabAvailabilityConditions[productTabToCheck](eligibleProducts, products, statuses);
};
