import React from 'react';
import { bool, number, string } from 'prop-types';
import { NavigationItem } from '@nutkit/component-navigation';
import { Text, textSizes, textWeights } from '@nutkit/component-text';
import Amount, { amountStyle, amountSizes, amountTypes } from '@nutkit/component-amount';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import { Flags, useFlag } from '@nm-utils-lib-web/flags';

import {
  productListProductSelectedPayload,
  productListStrategyTabClickedPayload,
} from '../../../../tracking/events/productList';
import { PRODUCT_EMPTY_TOTAL_AMOUNT } from '../../constants/TotalAmount';

export const ProductNavigationItem = ({ id, url, label, isPresent, total, isActive, 'data-qa': dataQa }) => {
  const isIncomePortfolioEnabled = useFlag(Flags.FE_INCOME_PORTFOLIOS_EI);

  return (
    <NavigationItem isActive={isActive} data-qa={dataQa}>
      <LinkWithTracking
        href={url}
        eventPayload={
          isIncomePortfolioEnabled
            ? productListStrategyTabClickedPayload(id)
            : productListProductSelectedPayload({ wrapperType: id })
        }
        isInternal
      >
        <Text
          textSize={textSizes.XS}
          textWeight={isActive ? textWeights.BOLD : textWeights.REGULAR}
          noStack
          noLineHeight
        >
          {label}
        </Text>
        <Text noStack noLineHeight>
          {isPresent ? (
            <Amount
              maxDecimals={0}
              style={amountStyle.CURRENCY}
              value={total}
              type={amountTypes.CONTRIBUTION}
              size={amountSizes.XS}
              noLineHeight
              noColor
            />
          ) : (
            PRODUCT_EMPTY_TOTAL_AMOUNT
          )}
        </Text>
      </LinkWithTracking>
    </NavigationItem>
  );
};

ProductNavigationItem.propTypes = {
  id: string.isRequired,
  url: string.isRequired,
  label: string.isRequired,
  isPresent: bool.isRequired,
  total: number.isRequired,
  isActive: bool.isRequired,
  'data-qa': string,
};

ProductNavigationItem.defaultProps = {
  'data-qa': 'product-navigation-item',
};
