import { Machine } from 'xstate';
import qs from 'qs';
import { TRANSFER_OPTIONS } from '@nm-payments/isa-transfer/constants/TransferOptions';
import { INTENT_OPTIONS } from '@nm-portfolio-lib-web/common/constants/IntentOptions';
import { ISA } from '@nm-portfolio-lib-web/common/constants/PotTypes';

import {
  NAVIGATION_EVENTS as NAVIGATION,
  NAVIGATION_ACTIONS as ACTIONS,
  JOURNEY_INTERNAL_STATES as STATES,
  EXTERNAL_VIEWS
} from '../constants';
import { changeView, trackNavigationEvent, updateLocationContext } from '../actions';
import {
  addTransition,
  addRedirectionTransition,
  addDirectedTransition,
  getInitialState,
  DEFAULT_GUARD,
  isTransferResponseAutomated
} from '../helpers';
import * as VIEWS from '../../constants/views';
import { TRANSFER_STATUS } from '../../constants/status';
import { DASHBOARD as NEXT_STEP_DASHBOARD } from '../../constants/declarationNextSteps';

/*
 * We make use of helper function addTransition to model our transitions and keep it dry
 * addTransition(target,guard,additionalActions)
 *
 * The guards can be let inline, so the journey is visible
 * Guard: func(event, meta, context )
 * meta can be used to introspect about the machine current state ( current view )
 * context is the machine's extended state,
 *
 * And the event object holds the event type and the app context in which happened
 * event({state, history, location, match, type}) - Use all of these for conditional journey
 */
const nextStepRedirectionToDashboard = addTransition(
  STATES.DASHBOARD,
  ({ state: { nextStep } }) => nextStep === NEXT_STEP_DASHBOARD
);
const journeyMachine = Machine(
  {
    id: 'journeyMachine',
    initial: STATES.INITIAL, // SET_INITIAL dynamicaly decides the first state based by guards that search the route
    states: {
      [STATES.INITIAL]: {
        always: getInitialState()
      },
      [VIEWS.OPEN_OR_TRANSFER]: {
        on: {
          [NAVIGATION.CONTINUE]: [
            addRedirectionTransition(
              EXTERNAL_VIEWS.PAYMENTS,
              ({ state: { intent } }, _, context) =>
                intent === INTENT_OPTIONS.OPEN && context.isaSettingsOnboardingAndVariantB
            ),
            addTransition(
              VIEWS.SET_ALLOWANCE,
              ({ state }) => state.intent === INTENT_OPTIONS.OPEN,
              ACTIONS.TRACK_NAVIGATION_EVENT
            ),
            addTransition(
              VIEWS.TRANSFER_TYPE,
              ({ state }) => state.intent === INTENT_OPTIONS.TRANSFER,
              ACTIONS.TRACK_NAVIGATION_EVENT
            )
          ]
        }
      },
      [VIEWS.SET_ALLOWANCE]: {
        on: {
          [NAVIGATION.CONTINUE]: [addTransition(VIEWS.DECLARATION, DEFAULT_GUARD, ACTIONS.TRACK_NAVIGATION_EVENT)],
          [NAVIGATION.BACK]: [
            addTransition(VIEWS.OPEN_OR_TRANSFER, ({ state: { intent } }) => intent === INTENT_OPTIONS.OPEN),
            addTransition(
              VIEWS.TRANSFER_TYPE,
              ({ state: { intent, transferType } }) =>
                intent === INTENT_OPTIONS.TRANSFER && transferType !== TRANSFER_OPTIONS.PREVIOUS_YEAR
            )
          ]
        }
      },
      [VIEWS.TRANSFER_TYPE]: {
        on: {
          [NAVIGATION.CONTINUE]: [
            addTransition(
              VIEWS.TRANSFER_FULL,
              ({ state: { intent, transferType } }, _, context) =>
                context.isaSettingsOnboardingAndVariantB &&
                intent === INTENT_OPTIONS.TRANSFER &&
                transferType !== TRANSFER_OPTIONS.PREVIOUS_YEAR &&
                transferType !== TRANSFER_OPTIONS.PARTIAL
            ),
            addTransition(
              VIEWS.TRANSFER_PARTIAL,
              ({ state: { intent, transferType } }, _, context) =>
                context.isaSettingsOnboardingAndVariantB &&
                intent === INTENT_OPTIONS.TRANSFER &&
                (transferType === TRANSFER_OPTIONS.PREVIOUS_YEAR || transferType === TRANSFER_OPTIONS.PARTIAL)
            ),
            addTransition(VIEWS.SET_ALLOWANCE, DEFAULT_GUARD, ACTIONS.TRACK_NAVIGATION_EVENT)
          ],
          [NAVIGATION.BACK]: addTransition(VIEWS.OPEN_OR_TRANSFER)
        }
      },
      [VIEWS.TRANSFER_OPEN_ISA]: {
        on: {
          [NAVIGATION.CONTINUE]: [
            addTransition(VIEWS.SET_ALLOWANCE, ({ state }) => state.openISA === true, ACTIONS.TRACK_NAVIGATION_EVENT),
            addTransition(VIEWS.TRANSFER_PARTIAL, ({ state }) => !state.openISA, ACTIONS.TRACK_NAVIGATION_EVENT)
          ],
          [NAVIGATION.BACK]: addTransition(VIEWS.TRANSFER_TYPE)
        }
      },
      [VIEWS.TRANSFER_FULL]: {
        on: {
          [NAVIGATION.CONTINUE]: [
            addTransition(
              VIEWS.TRANSFER_AUTO_SUCCESS,
              ({ state: { transferData } }) =>
                isTransferResponseAutomated(transferData) && transferData.status !== TRANSFER_STATUS.OTHER,
              ACTIONS.TRACK_NAVIGATION_EVENT
            ),
            addTransition(
              VIEWS.TRANSFER_PARTIAL,
              ({ state: { transferData } }) => !!transferData.fileId,
              ACTIONS.TRACK_NAVIGATION_EVENT
            ),
            addDirectedTransition(
              EXTERNAL_VIEWS.PAYMENTS,
              (_state, _, context) => context.isIncomePortfoliosFeatureEnabled
            ),
            addTransition(STATES.DASHBOARD)
          ]
        }
      },
      [VIEWS.TRANSFER_AUTO_SUCCESS]: {
        on: {
          [NAVIGATION.CONTINUE]: [
            addDirectedTransition(
              EXTERNAL_VIEWS.PAYMENTS,
              (_state, _, context) => context.isIncomePortfoliosFeatureEnabled
            ),
            addTransition(STATES.DASHBOARD, DEFAULT_GUARD, ACTIONS.TRACK_NAVIGATION_EVENT)
          ],
          [NAVIGATION.BACK]: addTransition(VIEWS.TRANSFER_FULL, DEFAULT_GUARD, ACTIONS.TRACK_NAVIGATION_EVENT)
        }
      },
      [VIEWS.TRANSFER_PARTIAL]: {
        on: {
          [NAVIGATION.CONTINUE]: [
            addDirectedTransition(
              EXTERNAL_VIEWS.PAYMENTS,
              (_state, _, context) => context.isIncomePortfoliosFeatureEnabled
            ),
            addTransition(STATES.DASHBOARD, DEFAULT_GUARD)
          ],
          [NAVIGATION.BACK]: [addTransition(VIEWS.TRANSFER_TYPE)]
        }
      },
      [VIEWS.DECLARATION]: {
        on: {
          [NAVIGATION.CONTINUE]: [
            addTransition(VIEWS.OPEN_OR_TRANSFER, (_evt, _state, context) => context.isaSettingsOnboardingAndVariantB),
            nextStepRedirectionToDashboard,
            addTransition(
              VIEWS.TRANSFER_FULL,
              ({ state: { intent, transferType } }) =>
                intent === INTENT_OPTIONS.TRANSFER &&
                transferType !== TRANSFER_OPTIONS.PREVIOUS_YEAR &&
                transferType !== TRANSFER_OPTIONS.PARTIAL
            ),
            addTransition(
              VIEWS.TRANSFER_PARTIAL,
              ({ state: { intent, transferType } }) =>
                intent === INTENT_OPTIONS.TRANSFER &&
                (transferType === TRANSFER_OPTIONS.PREVIOUS_YEAR || transferType === TRANSFER_OPTIONS.PARTIAL)
            ),
            addTransition(STATES.DASHBOARD, ({ state: { intent }, pot }) => intent === INTENT_OPTIONS.OPEN && !pot),
            addRedirectionTransition(EXTERNAL_VIEWS.PAYMENTS, ({ state: { intent } }) => intent === INTENT_OPTIONS.OPEN)
          ],
          [NAVIGATION.BACK]: [
            nextStepRedirectionToDashboard, // fallback if back event is triggered
            addTransition(VIEWS.SET_ALLOWANCE)
          ]
        }
      },
      [EXTERNAL_VIEWS.PAYMENTS]: {
        entry: []
      },
      [STATES.REDIRECTING_BACKWARD]: {
        // actions declared on entry will happen before the route has changed
        entry: []
      },
      [STATES.DASHBOARD]: {
        entry: []
      }
    },
    on: {
      [NAVIGATION.BROWSER_HISTORY_UPDATE]: {
        target: STATES.INITIAL,
        actions: [ACTIONS.UPDATE_LOCATION_CONTEXT]
      }
    }
  },
  {
    actions: {
      [ACTIONS.CHANGE_VIEW]: (context, event, meta) => changeView(event, meta, context),
      [ACTIONS.DIRECT_TO_COMBINED_PAYMENT]: (context, event, meta) => {
        const searchQueryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        const queryParams = {
          ...searchQueryParams,
          'pot-transferred': true
        };
        const customQueryString = qs.stringify(queryParams, { addQueryPrefix: true });

        return changeView(event, meta, context, true, customQueryString);
      },
      [ACTIONS.REDIRECT_VIEW]: (context, event, meta) => {
        const {
          pot: {
            contributions: { monthly, startingLumpSum }
          }
        } = event;
        const searchQueryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        const queryParams = {
          ...searchQueryParams,
          'product-type': ISA,
          lumpsum: startingLumpSum,
          monthly
        };
        const customQueryString = qs.stringify(queryParams, { addQueryPrefix: true });

        return changeView(event, meta, context, true, customQueryString);
      },
      [ACTIONS.UPDATE_LOCATION_CONTEXT]: updateLocationContext,
      [ACTIONS.TRACK_NAVIGATION_EVENT]: trackNavigationEvent
    }
  }
);

export default journeyMachine;
