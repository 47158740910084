import getWrapperTypeForTracking from '@nm-pot/overview/helpers/getWrapperTypeForTracking';

export const CREATE_NEW_POT_CLICKED = 'Create_New_Pot_Clicked';
export const PRODUCT_LIST_PRODUCT_SELECTED = 'Product_List_Product_Selected';
export const PRODUCT_LIST_EMPTY_PRODUCT_CLICKED = 'Empty_Product_Clicked';
export const CREATE_NEW_CASH_POT_CLICKED = 'Create_New_Cash_Pot_Clicked';
export const CANCEL_PENDING_WITHDRAWALS = 'Cancel_PW_Clicked';
export const CANCEL_PENDING_WITHDRAWAL_MODAL_CLICKED = 'Cancel_PW_Modal_Clicked';
export const REALLOCATE_MONEY_CLICKED = 'Reallocate_Money_Clicked';
export const REALLOCATE_MONEY_MODAL_CLICKED = 'Reallocate_Money_Modal_Clicked';
export const STRATEGY_TAB_CLICKED = 'Strategy_Tab_Clicked';
export const INCOME_BALANCE_CLICKED = 'Income_Balance_Clicked';

export const createNewPotClickedPayload = ({ wrapperType }) => ({
  name: CREATE_NEW_POT_CLICKED,
  properties: {
    category: 'Open pot',
    product: getWrapperTypeForTracking(wrapperType),
  },
});

export const productListProductSelectedPayload = ({ wrapperType }) => ({
  name: PRODUCT_LIST_PRODUCT_SELECTED,
  properties: {
    category: 'navigation',
    product: getWrapperTypeForTracking(wrapperType),
  },
});

export const productListStrategyTabClickedPayload = (strategyTab) => ({
  name: STRATEGY_TAB_CLICKED,
  properties: {
    tab: strategyTab,
  },
});

export const productListEmptyProductClickedPayload = ({ option, wrapperType }) => ({
  name: PRODUCT_LIST_EMPTY_PRODUCT_CLICKED,
  properties: {
    option,
    product: getWrapperTypeForTracking(wrapperType),
  },
});

export const createNewCashPotClickedPayload = ({ wrapperType }) => ({
  name: CREATE_NEW_CASH_POT_CLICKED,
  properties: {
    category: 'Open pot',
    product: `${getWrapperTypeForTracking(wrapperType)}_Pot`,
  },
});

export const cancelAllPendingWithdrawalsPayload = () => ({
  name: CANCEL_PENDING_WITHDRAWALS,
  properties: {
    category: 'Pending transactions',
  },
});

export const modalConfirmPendingWithdrawalsPayload = ({ action }) => ({
  name: CANCEL_PENDING_WITHDRAWAL_MODAL_CLICKED,
  properties: {
    action,
    category: 'Pending transactions',
  },
});

export const reallocateMoneyClickedPayload = () => ({
  name: REALLOCATE_MONEY_CLICKED,
  properties: {
    category: 'pending_transactions',
  },
});

export const reallocateMoneyModalClickedPayload = ({ action }) => ({
  name: REALLOCATE_MONEY_MODAL_CLICKED,
  properties: {
    action,
    category: 'pending_transactions',
  },
});
