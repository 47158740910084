import React from 'react';
import { bool, number, string } from 'prop-types';
import Inline, { inlineSpaces } from '@nutkit/component-inline';
import Image from '@nutkit/component-image';
import Thumbnail, { thumbnailSizes } from '@nutkit/component-thumbnail';
import Amount, { amountStyle, amountSizes, amountTypes } from '@nutkit/component-amount';

import { PRODUCT_EMPTY_TOTAL_AMOUNT } from '../../constants/TotalAmount';

export const ProductSelectOptionSelectedTitle = ({ imageSource, total, label, isPresent }) => (
  <Inline space={inlineSpaces.SM}>
    <Inline space={inlineSpaces.SM}>
      {imageSource && (
        <Thumbnail size={thumbnailSizes.XS}>
          <Image src={imageSource} alt={label} />
        </Thumbnail>
      )}
      {label}
    </Inline>
    {isPresent ? (
      <Amount
        maxDecimals={0}
        style={amountStyle.CURRENCY}
        value={total}
        type={amountTypes.CONTRIBUTION}
        size={amountSizes.SM}
        noLineHeight
        noColor
      />
    ) : (
      PRODUCT_EMPTY_TOTAL_AMOUNT
    )}
  </Inline>
);

ProductSelectOptionSelectedTitle.propTypes = {
  imageSource: string.isRequired,
  total: number.isRequired,
  label: string.isRequired,
  isPresent: bool.isRequired
};
