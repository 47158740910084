import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@nutkit/component-text';
import Section from '@nutkit/component-section';
import { useTranslation } from '@nm-utils-lib-web/translations';

import { Sisa } from './components/SISA';
import { Lisa } from './components/LISA';
import { Pensions } from './components/Pensions';
import { Jisa } from './components/JISA';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList';

export const InvestTowardsNewGoal = ({
  userUuid,
  isPensionEligible,
  isPensionPresent,
  eligibleSISA,
  eligibleGIA,
  isLisaPresent,
  isLisaDraftPresent,
  isLisaEligible,
  isJisaEligible,
  isJisaPresent,
  shouldShowLisaTransfersInTab,
  shouldShowSisaSectionOnly
}) => {
  const { t } = useTranslation();
  const shouldShowSisaSection = eligibleSISA || eligibleGIA;
  const shouldShowLisaSection = !isLisaPresent && !isLisaDraftPresent && isLisaEligible;
  const shouldShowPensionSection = !isPensionPresent && isPensionEligible;
  const shouldShowJisaSection = isJisaEligible;

  function renderRemainingSections() {
    if (shouldShowSisaSectionOnly) {
      return null;
    } else {
      return (
        <>
          {shouldShowLisaSection && <Lisa shouldShowLisaTransfersInTab={shouldShowLisaTransfersInTab} />}
          {shouldShowPensionSection && <Pensions />}
          {shouldShowJisaSection && <Jisa userUuid={userUuid} isJisaPresent={isJisaPresent} />}
        </>
      );
    }
  }

  return (
    <Section>
      <Heading level="3"> {t(`${TRANSLATION_NAMESPACE}.startInvestingTowardsNewGoal.label`)}</Heading>
      {shouldShowSisaSection ? <Sisa /> : null}
      {renderRemainingSections()}
    </Section>
  );
};

InvestTowardsNewGoal.propTypes = {
  userUuid: PropTypes.string.isRequired,
  isPensionEligible: PropTypes.bool.isRequired,
  isPensionPresent: PropTypes.bool.isRequired,
  eligibleSISA: PropTypes.bool.isRequired,
  eligibleGIA: PropTypes.bool.isRequired,
  isLisaPresent: PropTypes.bool.isRequired,
  isLisaDraftPresent: PropTypes.bool.isRequired,
  isLisaEligible: PropTypes.bool.isRequired,
  isJisaEligible: PropTypes.bool.isRequired,
  isJisaPresent: PropTypes.bool.isRequired,
  shouldShowLisaTransfersInTab: PropTypes.bool,
  shouldShowSisaSectionOnly: PropTypes.bool
};

InvestTowardsNewGoal.defaultProps = {
  shouldShowLisaTransfersInTab: false,
  shouldShowSisaSectionOnly: false
};
