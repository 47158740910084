import { event } from '@nm-utils-lib-web/analytics';

export const NOTIFICATION_BANNER_CLICKED = 'Notification_Banner_Clicked';
export const NOTIFICATION_BANNER_TYPES = {
  MARKETING: 'marketing',
  ANNUAL_REVIEW: 'annual_review',
  SECURITY_QUESTIONS: 'security_questions'
};
export const ACCOUNT_RESTRICTIONS_DASHBOARD_BANNER = 'Account_Restrictions_Dashboard_Banner';

export const notificationBannerClickedPayload = ({ type }) => ({
  name: NOTIFICATION_BANNER_CLICKED,
  properties: {
    category: 'Banner_Displayed',
    type,
    interaction: 'link_clicked'
  }
});

export const notificationBannerClosedPayload = ({ type }) => ({
  name: NOTIFICATION_BANNER_CLICKED,
  properties: {
    category: 'Banner_Displayed',
    type,
    interaction: 'banner_closed'
  }
});

export const trackNotificationBannerClosed = ({ type }) => event(notificationBannerClosedPayload({ type }));

export const trackCustomerEligibilityError = () => {
  event({
    name: 'Account_Restrictions_Dashboard_Banner',
    properties: {
      category: 'Banner_Displayed'
    }
  });
};
