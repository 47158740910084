import React from 'react';
import { string, func, shape, array } from 'prop-types';
import { useGetUnallocatedCash } from '@nm-payments/common/hooks';
import { CASH, INCOME, GROWTH, WRAPPER_TYPES } from '@nm-pot/common/constants';

import getPendingWithdrawalData from '../../helpers/getPendingWithdrawalData';
import UnallocatedCash from '../UnallocatedCash';
import PendingWithdrawal from '../PendingWithdrawal';
import PendingTransfers from '../PendingTransfers';

const Notifications = ({ userUuid, products, updatePots }) => {
  const { hasUnallocatedCash, data } = useGetUnallocatedCash({ userUuid });
  const sisaPots = [...(products[WRAPPER_TYPES.SISA]?.data ?? []), ...(products[INCOME]?.data ?? [])]
  const pots = [...(products[INCOME]?.data ?? []), ...(products[GROWTH]?.data ?? []), ...(products[CASH]?.data ?? [])];
  const {
    pendingWithdrawalPots,
    totalPendingWithdrawals,
    hasPendingWithdrawals,
    hasPendingWrapperWithdrawals
  } = getPendingWithdrawalData({ pots: sisaPots });

  return (
    <>
      {hasPendingWithdrawals && (
        <PendingWithdrawal
          pendingWithdrawalPots={pendingWithdrawalPots}
          totalPendingWithdrawals={totalPendingWithdrawals}
          updatePots={updatePots}
          userUuid={userUuid}
          hasPendingWrapperWithdrawals={hasPendingWrapperWithdrawals}
          data-qa="product-list__income-tab__pending-withdrawal"
        />
      )}
      <PendingTransfers userUuid={userUuid} onCancel={updatePots} pots={pots} />
      {hasUnallocatedCash && <UnallocatedCash amount={data.currentValue} potUuid={data.uuid} />}
    </>
  );
};

Notifications.propTypes = {
  userUuid: string.isRequired,
  products: shape({ [INCOME]: shape({ data: array }), [GROWTH]: shape({ data: array }), [CASH]: shape({ data: array }) }).isRequired,
  updatePots: func.isRequired
};

export default Notifications;
