import React, { useMemo, useEffect } from 'react';
import { shape, string, func } from 'prop-types';
import { NotificationBanner, notificationLevels } from '@nutkit/component-notification';
import Section from '@nutkit/component-section';
import Bold, { boldWeights } from '@nutkit/component-bold';
import Amount, { amountStyle, amountSizes } from '@nutkit/component-amount';
import { Customer } from '@nm-utils-lib-web/routes';
import { useGetSecurityQuestions } from '@nm-customer/common/hooks';
import { Trans } from '@nm-utils-lib-web/translations';
import { isJohnLewisFinanceGBR } from '@nm-utils-lib-web/organisations';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import PrismicNotificationBanner from '@nm-marketing/component-notification-banner';
import { createNotificationBannerModel } from '@nm-marketing/prismic-models';
import { useGetEligibility, ELIGIBILITY_RESTRICTIONS } from '@nm-utils-lib-web/authorization';

import { useGetPerformanceSummary } from '../../../../hooks/useGetPerformanceSummary';
import { useGetBannerDashboardFromPrismic } from '../../../../hooks';
import { PENDING_ANNUAL_REVIEW } from '../../../../constants/AnnualReviewState';
import {
  NOTIFICATION_BANNER_TYPES,
  trackNotificationBannerClosed,
  notificationBannerClickedPayload,
  trackCustomerEligibilityError
} from '../../../../tracking/events/portfolioDashboardBannerEvents';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.notificationBanners';

const getNotifications = ({
  needsSecurityAnswers,
  isPendingAnualReview,
  aviosMarketingPromotionEnabled,
  t,
  userPerformanceSummary,
  prismicNotificationsBannerModel,
  testBannerEnabled,
  accountRestrictionsEnabled,
  hasOverseasRestriction
}) => {
  let notifications = [];
  const pendingWithdrawalValue = userPerformanceSummary && userPerformanceSummary.pendingWithdrawalValue;

  if (accountRestrictionsEnabled && hasOverseasRestriction) {
    notifications.push({
      component: (
        <NotificationBanner
          level={notificationLevels.WARNING}
          key="account-restrictions-overseas-notification"
          data-qa="dashboard__account-restrictions-overseas-notification"
        >
          <Trans
            i18nKey={`${TRANSLATION_NAMESPACE}.overseasRestriction.label`}
            components={[<Bold weight={boldWeights.BOLD}>{''}</Bold>]}
          />
        </NotificationBanner>
      )
    });
  }

  if (prismicNotificationsBannerModel) {
    prismicNotificationsBannerModel.forEach(notificationModel => {
      notifications.push({
        component: (
          <PrismicNotificationBanner
            key={notificationModel.bannerName}
            data-qa={`dashboard__prismic-notification-banner-${notificationModel.bannerName}`}
            trackClickLinkInteraction={notificationBannerClickedPayload({
              type: NOTIFICATION_BANNER_TYPES.MARKETING
            })}
            trackCloseBannerInteraction={() =>
              trackNotificationBannerClosed({
                type: NOTIFICATION_BANNER_TYPES.MARKETING
              })
            }
            {...notificationModel}
          />
        )
      });
    });
  }

  if (aviosMarketingPromotionEnabled) {
    notifications.push({
      component: (
        <NotificationBanner
          key="avios-marketing-promotion-notification"
          data-qa="dashboard__avios-marketing-promotion-notification"
        >
          {t(`${TRANSLATION_NAMESPACE}.aviosMarketingPromotion.label`)}{' '}
          <LinkWithTracking
            href="https://www.nutmeg.com/promo/existingtransferboost"
            eventPayload={notificationBannerClickedPayload({ type: NOTIFICATION_BANNER_TYPES.MARKETING })}
            isExternal
          >
            {t(`${TRANSLATION_NAMESPACE}.aviosMarketingPromotion.link`)}
          </LinkWithTracking>
        </NotificationBanner>
      )
    });
  }

  if (needsSecurityAnswers) {
    notifications.push({
      component: (
        <NotificationBanner key="security-questions-notification" data-qa="dashboard__security-questions-notification">
          {t(`${TRANSLATION_NAMESPACE}.securityQuestions.label`)}
          <LinkWithTracking
            href={Customer.CUSTOMER_SECURITY_QUESTIONS_PATH}
            aria-label={t(`${TRANSLATION_NAMESPACE}.securityQuestions.link.ariaLabel`)}
            data-qa="dashboard__security-questions-notification-link"
            eventPayload={notificationBannerClickedPayload({ type: NOTIFICATION_BANNER_TYPES.SECURITY_QUESTIONS })}
          >
            {t(`${TRANSLATION_NAMESPACE}.securityQuestions.link.label`)}
          </LinkWithTracking>
        </NotificationBanner>
      )
    });
  }

  if (isPendingAnualReview) {
    notifications.push({
      component: (
        <NotificationBanner
          key="pending-annual-review-notification"
          data-qa="dashboard__pending-annual-review-notification"
        >
          {t(`${TRANSLATION_NAMESPACE}.annualReviewPending.label`)}{' '}
          <LinkWithTracking
            href={Customer.CUSTOMER_ANNUAL_REVIEW_DETAILS_PATH}
            eventPayload={notificationBannerClickedPayload({ type: NOTIFICATION_BANNER_TYPES.ANNUAL_REVIEW })}
            data-qa="dashboard__pending-annual-review-notification-link"
          >
            {t(`${TRANSLATION_NAMESPACE}.annualReviewPending.link`)}
          </LinkWithTracking>
        </NotificationBanner>
      )
    });
  }

  if (pendingWithdrawalValue > 0) {
    notifications.push({
      component: (
        <NotificationBanner key="pending-withdrawal-notification" data-qa="dashboard__pending-withdrawal-notification">
          <Trans
            i18nKey={`${TRANSLATION_NAMESPACE}.pendingWithdrawal.label`}
            components={[
              <Bold weight={boldWeights.BOLD}>
                <Amount
                  value={pendingWithdrawalValue}
                  style={amountStyle.CURRENCY}
                  size={amountSizes.XS}
                  noColor
                  minDecimals={0}
                />
              </Bold>
            ]}
          />
        </NotificationBanner>
      )
    });
  }

  if (testBannerEnabled) {
    notifications.push({
      component: (
        <NotificationBanner key="test-notification" data-qa="dashboard__test-notification">
          Banner for e2e unleash strategies test
        </NotificationBanner>
      )
    });
  }

  return notifications;
};

export const PortfolioDashboardBanner = ({ userDetails, className, 'data-qa': dataQa, t }) => {
  const { annualReviewState, userUuid } = userDetails;
  const aviosMarketingPromotionFlag = useFlag(Flags.AVIOS_MARKETING_PROMOTION);
  const testBannerEnabled = useFlag(Flags.E2E_UNLEASH_STRATEGIES);
  const accountRestrictionsEnabled = useFlag(Flags.FE_ACCOUNT_RESTRICTIONS);
  const { needsSecurityAnswers } = useGetSecurityQuestions({
    customerUuid: userUuid
  });
  const { data: userPerformanceSummary } = useGetPerformanceSummary({
    customerUuid: userUuid
  });
  const { data: accountRestrictions } = useGetEligibility({
    customerUuid: userUuid,
    shouldMakeRequest: accountRestrictionsEnabled
  });
  const hasOverseasRestriction =
    accountRestrictions?.restrictions.some(restriction => restriction === ELIGIBILITY_RESTRICTIONS.OVERSEAS) ?? false;
  const { bannerDashboard } = useGetBannerDashboardFromPrismic({});
  const isPendingAnualReview = PENDING_ANNUAL_REVIEW.includes(annualReviewState);
  const prismicNotificationsBannerModel =
    bannerDashboard && bannerDashboard.map(notification => createNotificationBannerModel(notification));
  const aviosMarketingPromotionEnabled = aviosMarketingPromotionFlag && !isJohnLewisFinanceGBR();

  useEffect(() => {
    if (accountRestrictionsEnabled && hasOverseasRestriction) {
      trackCustomerEligibilityError();
    }
  }, [accountRestrictionsEnabled, hasOverseasRestriction]);

  const notifications = useMemo(
    () =>
      getNotifications({
        needsSecurityAnswers,
        isPendingAnualReview,
        aviosMarketingPromotionEnabled,
        t,
        userPerformanceSummary,
        prismicNotificationsBannerModel,
        testBannerEnabled,
        accountRestrictionsEnabled,
        hasOverseasRestriction
      }),
    [
      needsSecurityAnswers,
      isPendingAnualReview,
      aviosMarketingPromotionEnabled,
      t,
      userPerformanceSummary,
      prismicNotificationsBannerModel,
      testBannerEnabled,
      accountRestrictionsEnabled,
      hasOverseasRestriction
    ]
  );

  return notifications.length > 0 ? (
    <Section className={className} data-qa={dataQa}>
      {notifications.map(notification => notification.component)}
    </Section>
  ) : null;
};

PortfolioDashboardBanner.propTypes = {
  userDetails: shape({
    annualReviewState: string,
    custodianAccountNumber: string,
    firstName: string,
    lastName: string
  }),
  className: string,
  'data-qa': string,
  t: func.isRequired
};

PortfolioDashboardBanner.defaultProps = {
  userDetails: {},
  className: undefined,
  'data-qa': undefined
};

export default PortfolioDashboardBanner;
